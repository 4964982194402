<template>
    <div class="main__container main__padding" v-if="!token">
        <div class="loginBox column__center">
            <StepOneSvg style="width: 30%"/>
            <h1 class="text__semibold mt-25 mb-40">
                Schüler und Schülerinnen Anmeldung
            </h1>
            <form>
                <div class="searchbox row__start mb-25">
                    <input
                        type="text"
                        @keyup="removeErrorMessages"
                        v-model="userMail"
                        placeholder="E-Mail"
                    />
                    <p class="error__message" v-if="userError">
                        {{ userError }}
                    </p>
                </div>

                <div class="searchbox row__start mb-35">
                    <input
                        :type="seePassword ? 'text' : 'password'"
                        v-model="password"
                        @keyup="removeErrorMessages"
                        placeholder="Passwort"
                        autocomplete
                    />
                    <div @click="seePassword = !seePassword">
                        <SeeSvg
                            :classes="
                                seePassword
                                    ? 'svg__fill--primary'
                                    : 'svg__fill--grey700'
                            "
                        />
                    </div>
                </div>
            </form>
            <div
                v-if="passwordError"
                class="row mb-25"
                style="text-align: center; padding: 0px 10px"
            >
                <p style="position: relative; color: red">
                    {{ passwordError }}
                </p>
            </div>
            <div class="button mb-25" @click="prepareLogin">
                <p class="text__white">Anmelden</p>
            </div>
            <router-link :to="{ name: 'Register' }">
                <p class="text__grey text__hover--primary text__center mb-15">
                    Noch kein Konto? Schüler und Schülerinnen können sich hier
                    <strong>registrieren</strong>.
                </p>
            </router-link>
            <router-link :to="{ name: 'Register' }">
                <div class="button mb-25">
                    <p class="text__white">Registrieren</p>
                </div>
            </router-link>
            <router-link :to="{ name: 'Password' }">
                <p class="text__grey text__hover--primary text__center">
                    Passwort vergessen? Schüler und Schülerinnen können
                    <strong>hier ihr Passwort</strong>
                    zurücksetzen.
                </p>
            </router-link>
            <router-link :to="{ name: 'Password' }">
            <div class="button mt-15" style="width: 200px;">
                <p class="text__white">Passwort zurücksetzen</p>
            </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Login",
    data() {
        return {
            userMail: "",
            password: "",
            seePassword: false,
            isLoading: false,
            userError: "",
            passwordError: "",
        };
    },
    methods: {
        ...mapActions(["loginUser"]),
        removeErrorMessages() {
            this.userError = "";
            this.passwordError = "";
        },
        async prepareLogin() {
            let target = event.target;
            if (target.classList.contains("button")) {
                target = target.childNodes[0];
            }
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            target.innerHTML = "<div class='loading'></div>";
            let bool = await this.loginUser({
                email: this.userMail,
                password: this.password,
            });
            if (bool === true) {
                setTimeout(() => {
                    target.innerHTML = "Anmelden";
                    this.isLoading = false;
                    this.$router.push({
                        name: "Home",
                    });
                }, 500);
            } else if (bool.includes("Email")) {
                this.userError = bool;
            } else {
                this.passwordError = bool;
            }
            target.innerHTML = "Anmelden";
            this.isLoading = false;
        },
    },
    created() {
        if (this.token) {
            this.$router.push({
                name: "Home",
            });
        }
    },
    computed: {
        ...mapGetters(["token"]),
    },
};
</script>
