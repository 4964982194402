var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.token)?_c('div',{staticClass:"main__container main__padding"},[(!_vm.success)?_c('div',{staticClass:"loginBox column__center"},[_c('StepOneSvg',{staticStyle:{"width":"30%"}}),_c('h1',{staticClass:"text__semibold mt-25 mb-40"},[_vm._v("Registrieren")]),_c('form',{attrs:{"autocomplete":"off"}},[_c('p',[_vm._v("E-Mail")]),_c('div',{staticClass:"searchbox row__start mb-25",class:_vm.userMailValid === null
                        ? ''
                        : _vm.userMailValid
                        ? 'searchbox--green'
                        : 'searchbox--red'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userMail),expression:"userMail"}],attrs:{"type":"text","placeholder":"E-Mail"},domProps:{"value":(_vm.userMail)},on:{"keyup":_vm.checkEmail,"input":function($event){if($event.target.composing){ return; }_vm.userMail=$event.target.value}}}),(_vm.userError)?_c('p',{staticClass:"error__message",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.userError)+" ")]):_vm._e()]),_c('div',{staticClass:"searchbox row__start mb-25",class:_vm.confirmUserMailValid === null
                        ? ''
                        : _vm.confirmUserMailValid
                        ? 'searchbox--green'
                        : 'searchbox--red'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmUserMail),expression:"confirmUserMail"}],attrs:{"type":"text","placeholder":"E-Mail wiederholen"},domProps:{"value":(_vm.confirmUserMail)},on:{"keyup":_vm.checkEmail,"input":function($event){if($event.target.composing){ return; }_vm.confirmUserMail=$event.target.value}}}),(_vm.confirmUserError)?_c('p',{staticClass:"error__message",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.confirmUserError)+" ")]):_vm._e()]),_c('p',[_vm._v("Passwort")]),_c('div',{staticClass:"searchbox row__start mb-25",class:_vm.passwordValid === null
                        ? ''
                        : _vm.passwordValid
                        ? 'searchbox--green'
                        : 'searchbox--red'},[((_vm.seePassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"placeholder":"Passwort","autocomplete":"","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"keyup":_vm.checkPasswordStrength,"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.seePassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"placeholder":"Passwort","autocomplete":"","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"keyup":_vm.checkPasswordStrength,"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"placeholder":"Passwort","autocomplete":"","type":_vm.seePassword ? 'text' : 'password'},domProps:{"value":(_vm.password)},on:{"keyup":_vm.checkPasswordStrength,"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{on:{"click":function($event){_vm.seePassword = !_vm.seePassword}}},[_c('SeeSvg',{attrs:{"classes":_vm.seePassword
                                ? 'svg__fill--primary'
                                : 'svg__fill--grey700'}})],1),(_vm.passwordError)?_c('p',{staticClass:"error__message",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.passwordError)+" ")]):_vm._e()]),_c('div',{staticClass:"searchbox row__start mb-35",class:_vm.confirmPasswordValid === null
                        ? ''
                        : _vm.confirmPasswordValid
                        ? 'searchbox--green'
                        : 'searchbox--red'},[((_vm.seeConfirmPassword ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],attrs:{"placeholder":"Passwort bestätigen","autocomplete":"","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.confirmPassword)?_vm._i(_vm.confirmPassword,null)>-1:(_vm.confirmPassword)},on:{"keyup":_vm.checkPasswordStrength,"change":function($event){var $$a=_vm.confirmPassword,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.confirmPassword=$$a.concat([$$v]))}else{$$i>-1&&(_vm.confirmPassword=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.confirmPassword=$$c}}}}):((_vm.seeConfirmPassword ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],attrs:{"placeholder":"Passwort bestätigen","autocomplete":"","type":"radio"},domProps:{"checked":_vm._q(_vm.confirmPassword,null)},on:{"keyup":_vm.checkPasswordStrength,"change":function($event){_vm.confirmPassword=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],attrs:{"placeholder":"Passwort bestätigen","autocomplete":"","type":_vm.seeConfirmPassword ? 'text' : 'password'},domProps:{"value":(_vm.confirmPassword)},on:{"keyup":_vm.checkPasswordStrength,"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('div',{on:{"click":function($event){_vm.seeConfirmPassword = !_vm.seeConfirmPassword}}},[_c('SeeSvg',{attrs:{"classes":_vm.seeConfirmPassword
                                ? 'svg__fill--primary'
                                : 'svg__fill--grey700'}})],1),(_vm.confirmPasswordError)?_c('p',{staticClass:"error__message",staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.confirmPasswordError)+" ")]):_vm._e()])]),_c('button',{staticClass:"button",class:!_vm.userMailValid || !_vm.passwordValid || !_vm.confirmPasswordValid || !_vm.confirmUserMailValid 
                    ? 'button--grey'
                    : '',on:{"click":_vm.checkRegistration}},[_c('p',{staticClass:"text__white"},[_vm._v("Registrieren")])])],1):_vm._e(),(_vm.success)?_c('div',{staticClass:"loginBox column__center"},[_c('StepOneSvg'),_c('h1',{staticClass:"text__semibold mt-25 mb-40"},[_vm._v("Registrieren")]),_c('p',{staticClass:"mr-8 ml-8"},[_vm._v(" Vielen Dank für deine Registrierung! ")]),_c('p',{staticClass:"mr-8 ml-8 mt-15"},[_vm._v(" Du erhältst in Kürze eine E-Mail von uns. Bitte überprüfe dein E-Mail-Postfach (inklusive dem SPAM-Verzeichnis) und klicke auf den darin enthaltenen Link, um deine E-Mail-Adresse zu bestätigen. ")]),_c('p',{staticClass:"mr-8 ml-8 mt-15"},[_vm._v(" Anschließend kannst du dich direkt "),_c('router-link',{staticClass:"user__link",attrs:{"to":{ name: 'Login' }}},[_vm._v(" hier anmelden. ")])],1)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }