<template>
    <div class="main__container main__padding" v-if="!token">
        <div class="loginBox column__center">
            <StepOneSvg style="width: 30%" />
            <h1 class="text__semibold mt-25 mb-40">
                Passwort zurücksetzen
            </h1>
            <p class="text__center text__grey mb-30">
                Bitte gib deine E-Mail-Adresse für das Zurücksetzen des
                Passwortes an. <br />
                Dir wird dann eine E-Mail mit einem Link zugeschickt.
            </p>
            <form>
                <div class="searchbox row__start mb-25">
                    <input
                        type="email"
                        @keyup="checkEmail"
                        v-model="userMail"
                        placeholder="E-Mail"
                    />
                    <p class="error__message" v-if="userError">
                        {{ userError }}
                    </p>
                </div>
            </form>

            <div class="button mt-25" @click="prepareSubmit">
                <p class="text__white">Zurücksetzen</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Password",
    data() {
        return {
            userMail: "",
            userMailValid: null,
            // isLoading: false,
            userError: "",
        };
    },
    methods: {
        ...mapActions(["requestResetLink"]),
        removeErrorMessages() {
            this.userError = "";
        },
        async prepareSubmit() {
            if (this.userMailValid) {
                const succeed = await this.requestResetLink(this.userMail);
                if (succeed.msg) {
                    this.userError = succeed.msg;
                }
            }
        },
        checkEmail() {
            this.userError = "";
            const checkedMail = this.userMail.split(" ").join("");
            const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.userMailValid = re.test(String(checkedMail).toLowerCase());
            if (this.userMailValid) {
                // this.checkPasswordStrength();
            } else {
                this.userError = "Dies ist keine gültige E-Mail.";
            }
        },
    },
    computed: {
        ...mapGetters(["token"]),
    },
    created() {
        if (this.token) {
            this.$router.push({
                name: "Home",
            });
        }
    },
};
</script>

<style></style>
