<template>
    <div class="main__container main__padding" v-if="!token">
        <div class="loginBox column__center" v-if="!success">
            <StepOneSvg style="width: 30%"/>
            <h1 class="text__semibold mt-25 mb-40">Registrieren</h1>
            <form autocomplete="off">
                <p>E-Mail</p>
                <div
                    class="searchbox row__start mb-25"
                    :class="
                        userMailValid === null
                            ? ''
                            : userMailValid
                            ? 'searchbox--green'
                            : 'searchbox--red'
                    "
                >
                    <input
                        type="text"
                        v-model="userMail"
                        placeholder="E-Mail"
                        @keyup="checkEmail"
                    />
                    <p class="error__message" v-if="userError" style="white-space: nowrap;">
                        {{ userError }}
                    </p>
                </div>
                <div
                    class="searchbox row__start mb-25"
                    :class="
                        confirmUserMailValid === null
                            ? ''
                            : confirmUserMailValid
                            ? 'searchbox--green'
                            : 'searchbox--red'
                    "
                >
                    <input
                        type="text"
                        v-model="confirmUserMail"
                        placeholder="E-Mail wiederholen"
                        @keyup="checkEmail"
                    />
                    <p class="error__message" v-if="confirmUserError" style="white-space: nowrap;">
                        {{ confirmUserError }}
                    </p>
                </div>

                <p>Passwort</p>
                <div
                    class="searchbox row__start mb-25"
                    :class="
                        passwordValid === null
                            ? ''
                            : passwordValid
                            ? 'searchbox--green'
                            : 'searchbox--red'
                    "
                >
                    <input
                        :type="seePassword ? 'text' : 'password'"
                        v-model="password"
                        placeholder="Passwort"
                        autocomplete
                        @keyup="checkPasswordStrength"
                    />
                    <div @click="seePassword = !seePassword">
                        <SeeSvg
                            :classes="
                                seePassword
                                    ? 'svg__fill--primary'
                                    : 'svg__fill--grey700'
                            "
                        />
                    </div>
                    <p class="error__message" v-if="passwordError" style="white-space: nowrap;">
                        {{ passwordError }}
                    </p>
                </div>
                <div
                    class="searchbox row__start mb-35"
                    :class="
                        confirmPasswordValid === null
                            ? ''
                            : confirmPasswordValid
                            ? 'searchbox--green'
                            : 'searchbox--red'
                    "
                >
                    <input
                        :type="seeConfirmPassword ? 'text' : 'password'"
                        v-model="confirmPassword"
                        placeholder="Passwort bestätigen"
                        autocomplete
                        @keyup="checkPasswordStrength"
                    />
                    <div @click="seeConfirmPassword = !seeConfirmPassword">
                        <SeeSvg
                            :classes="
                                seeConfirmPassword
                                    ? 'svg__fill--primary'
                                    : 'svg__fill--grey700'
                            "
                        />
                    </div>
                    <p class="error__message" v-if="confirmPasswordError" style="white-space: nowrap;">
                        {{ confirmPasswordError }}
                    </p>
                </div>
            </form>

            <button
                class="button"
                :class="
                    !userMailValid || !passwordValid || !confirmPasswordValid || !confirmUserMailValid 
                        ? 'button--grey'
                        : ''
                "
                @click="checkRegistration"
            >
                <p class="text__white">Registrieren</p>
            </button>
        </div>
        <div class="loginBox column__center" v-if="success">
            <StepOneSvg />
            <h1 class="text__semibold mt-25 mb-40">Registrieren</h1>
            <p class="mr-8 ml-8">
                Vielen Dank für deine Registrierung! 
            </p>
            <p class="mr-8 ml-8 mt-15">
                Du erhältst in Kürze eine E-Mail von uns. 
                Bitte überprüfe dein E-Mail-Postfach (inklusive dem SPAM-Verzeichnis) und klicke auf den darin enthaltenen Link, um deine E-Mail-Adresse zu bestätigen.
            </p>
            <p class="mr-8 ml-8 mt-15">
                Anschließend kannst du dich direkt
                <router-link
                    :to="{ name: 'Login' }"
                    class="user__link"
                    >
                    hier anmelden.
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Register",
    data() {
        return {
            userMail: "",
            userMailValid: null,
            confirmUserMail: null,
            confirmUserMailValid: null,

            password: "",
            seePassword: false,
            passwordValid: null,

            confirmPassword: "",
            seeConfirmPassword: false,
            confirmPasswordValid: null,

            fillTextArray: [],
            userError: "",
            confirmUserError: "",
            passwordError: "",
            confirmPasswordError: "",

            isLoading: false,

            success: false,
        };
    },
    methods: {
        ...mapActions(["registerUser"]),
        async checkRegistration() {
            let target = event.target;
            if (target.classList.contains("button")) {
                target = target.childNodes[0];
            }
            if (
                !this.userMailValid ||
                !this.confirmUserMailValid ||
                !this.passwordValid ||
                !this.confirmPasswordValid ||
                this.isLoading
            ) {
                return;
            }
            this.isLoading = true;
            target.innerHTML = "<div class='loading'></div>";
            const bool = await this.registerUser({
                mail: this.userMail,
                password: this.password,
                confirmPassword: this.confirmPassword,
            });
            if (bool === true) {
                this.success = true;
            } else if (bool) {
                this.userError = bool;
                target.innerHTML = "Anmelden";
                this.isLoading = false;
            } else {
                this.userError = "Bitte später probieren.";
                target.innerHTML = "Anmelden";
                this.isLoading = false;
            }
        },
        checkPasswordStrength() {
            const password = this.password;
            this.passwordError = "";
            this.confirmPasswordError = "";
            this.passwordValid = false;
            if (!/[A-ZÄÖÜ]/.test(password)) {
                this.passwordError = "Mindestens ein Großbuchstaben.";
                return;
            }
            if (!/[a-zäöü]/.test(password)) {
                this.passwordError = "Mindestens ein Kleinbuchstaben.";
                return;
            }
            if (!/[0-9]/.test(password)) {
                this.passwordError = "Mindestens eine Zahl.";
                return;
            }
            if (!/[^A-ZÄÖÜa-zäöü0-9]/.test(password)) {
                this.passwordError = "Mindestens ein Sonderzeichen.";
                return;
            }
            if (password.length <= 5) {
                this.passwordError = "Mindestens 6 Zeichen.";
                return;
            }
            this.passwordValid = true;
            this.confirmPasswordValid = false;

            if (this.password === this.confirmPassword) {
                this.confirmPasswordValid = true;
            } else {
                this.confirmPasswordError = "Passwörter stimmen nicht überein.";
            }
        },
        checkEmail() {
            this.userError = "";
            this.confirmUserError = "";
            
            let checkedMail = this.userMail.split(" ").join("");
            const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.userMailValid = re.test(String(checkedMail).toLowerCase());
            if (this.userMailValid) {
                this.checkPasswordStrength();
            } else {
                this.userError = "Dies ist keine gültige E-Mail.";
            }

            if (this.userMail === this.confirmUserMail) {
                this.confirmUserMailValid = true;
            } else {
                this.confirmUserMailValid = false;
                this.confirmUserError = "E-Mails stimmen nicht überein.";
            }
        },
    },
    created() {
        if (this.token) {
            this.$router.push({
                name: "Home",
            });
        }
    },
    computed: {
        ...mapGetters(["token"]),
    },
    mounted() {},
};
</script>
